<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="title">
        账号信息
        <div class="subTitle">
          还没有Paypal账号？
          <span @click="toPaypal">点此申请</span>
        </div>
      </div>
      <el-form-item label="clientid：" prop="clientid">
        <el-input v-model="ruleForm.clientid"></el-input>
      </el-form-item>
      <el-form-item label="secret：" prop="secret">
        <el-input v-model="ruleForm.secret"></el-input>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      id: null,
      is_edit: false,
      ruleForm: {
        clientid: '',
        secret: '',
      },
      rules: {
        clientid: [{ required: true, message: '请填写clientid', trigger: 'blur' }],
        secret: [{ required: true, message: '请填写secret', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    toPaypal() {
      window.open('https://www.paypal.com/c2/webapps/mpp/account-selection');
    },
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {
            sets: this.ruleForm,
            type: 13,
          };
          let path = this.is_edit ? this.$api.set.Settingedit : this.$api.set.Settingadd;
          if (this.is_edit) obj.id = this.id;
          this.$axios.post(path, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.errro(res.msg);
            }
          });
        }
      });
    },
    getConfig() {
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 13,
        })
        .then(res => {
          if (res.code == 0) {
            if (res.result.info !== '') {
              this.is_edit = true;
              this.id = res.result.info.id;
              this.ruleForm = res.result.info.sets;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 80px;
  .title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    margin-bottom: 20px;
    .subTitle {
      font-size: 12px;
      align-self: flex-end;
      margin-left: 20px;
      color: #666;
      span {
        color: #409eff;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .el-form {
    width: 400px;
  }
}
</style>
